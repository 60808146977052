import Layout from "../components/layout/Layout"
import FeaturedPost from '../components/cards/FeaturedPost';
import BlogPost from '../components/cards/BlogPost';
import PopularList from '../components/lists/Popular';
import React from 'react'
import { graphql } from 'gatsby';
import SEO from '../components/SEO';

function Blog({ data: { allContentfulPost } }) {

  const { posts } = allContentfulPost
  const featuredPosts = posts && posts.filter(post => post.featured)

  const recentPosts = posts && posts.filter(post => !post.featured)

  return (<>
    <SEO
      title="Student Advice Blog"
      image="https://theultimateessayguide.com/ueg-blog-cover-small.jpg"
      description="From tips and tricks to memes on student life, the Ultimate Essay Guide blog is as varied and occasionally random as student life itself. With articles for the studious and posts for the less studios... we support students throughout their college and university experiences."
    />
    <Layout

    >


      {featuredPosts ?
        <section className="w-full px-2 max-w-screen-xl mx-auto">
          <h1 className="text-4xl font-bold text-center text-primary mb-12 mt-8 lg:my-8 tracking-wide">Featured Posts</h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {featuredPosts && featuredPosts.map(((post, index) => <FeaturedPost key={post.slug + index} {...post} />))}
          </div>
        </section> : ""
      }
      <section className="w-full px-2 max-w-screen-lg mx-auto">
        <header className="gap-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:gap-8 mt-12">
          <div>
            <h2 className="text-3xl text-center">Popular Posts</h2>
            <div className="h-2 bg-gradient-l-golden  mx-auto rounded-full w-20"></div>
          </div>
          <div className="md:col-span-2 hidden sm:block">
            <h2 className="text-3xl text-center">Recent Posts</h2>
            <div className="h-2 bg-gradient-l-golden  mx-auto rounded-full w-20"></div>
          </div>
        </header>

        <section className="grid grid-row-3 grid-flow-row sm:grid-cols-2 md:grid-cols-3 gap-8 pt-12" >
          <PopularList className="space-y-12 mb-12 md:mb-0 row-span-2" />
          <div className="sm:hidden">
            <h2 className="text-3xl text-center">Recent Posts</h2>
            <div className="h-2 bg-gradient-l-golden  mx-auto rounded-full w-20"></div>
          </div>
          {recentPosts && recentPosts.map(((post, index) => <BlogPost key={post.slug + index} {...post} />))}
        </section>

      </section>

    </Layout>
  </>
  )
}



export const BlogQuery = graphql`

query BlogQuery {
  allContentfulPost(sort: {fields: published, order: DESC}) {
    posts: nodes {
      published
      readingTime
      slug
      title
      description
      featured
      coverImage {
        fluid(quality: 100, maxWidth: 350) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
      author {
        photo {
          fixed(width: 50, quality: 50) {
            ...GatsbyContentfulFixed_withWebp
          }
          title
        }
        name
      }
    }
  }
}





`

export default Blog