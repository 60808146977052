import React from 'react'
import PropTypes from 'prop-types'
import Linked from '../elements/Linked';
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';

const PopularList = (props) => {


    const { PopularPosts: { posts } } = useStaticQuery(graphql`
        query PopularPostQuery {
        PopularPosts: contentfulPopularPosts {
            posts {
                slug
                id
                title
                }
            }
        }
    `)



    return (
        <ol {...props}>
            {posts && posts.map((post, index) => <PopularItem key={post.slug + index} index={index + 1} {...post} />)}
        </ol>
    )
}


const PopularItem = ({ title, slug, index }) => {
    return <Linked linkTo={`/blog/${slug}`}>
        <li className="bg-white shadow hover:shadow-xl my-6 transition-shadow duration-300 p-4 mx-auto text-center rounded relative cursor-pointer">
            <div className="absolute h-10 w-10 rounded-full bg-primary text-white font-bold text-xl pt-1 " style={{ top: -14, left: -14 }}>
                {index}
            </div>
            <h4 className="text-primary text-xl font-bold">{title}</h4>
        </li>
    </Linked>
}


PopularItem.defaultProps = {
    title: "10 Easy Steps to an Amazing Essay",
    slug: ""
}
PopularList.defaultProps = {
    posts: [
        {},
        {},
        {}
    ]
}

PopularList.propTypes = {

}

export default PopularList
