import React from 'react'
import PropTypes from 'prop-types'
import Author from './Author';
import Linked from '../elements/Linked';
import  Img from 'gatsby-image';

const FeaturedPost = ({ title, description, author, slug, coverImage, published, readingTime }) => {

    // const image = typeof coverImage !== "string" ? useContentfulImage(coverImage) : coverImage

    return (
        <Linked linkTo={`/blog/${slug}`} >
            <div className="flex flex-col sm:flex-row rounded shadow hover:shadow-xl transition-shadow duration-300 cursor-pointer bg-white h-full">
                <div className="order-2 sm:order-1 sm:w-1/2 p-4 flex flex-col justify-between space-y-4 ">
                    <div >
                         <h3 className="text-2xl tracking-wide mb-2 ">{title}</h3>
                        <p className="text-sm leading-relaxed font-thin">{description}</p>
                    </div>
                   
                    <Author author={author} published={published} readingTime={readingTime} />
                </div>
                <div className="order-1 sm:order-2 sm:w-1/2 bg-primary rounded-t sm:rounded-r">
                    <Img className="w-full h-56 sm:h-full flex rounded-t sm:rounded-r" fluid={coverImage.fluid} />
                </div>
            </div>
        </Linked>
    )
}

FeaturedPost.propTypes = {

}

FeaturedPost.defaultProps = {
    title: "Blog Post Title",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, vitae maxime. Reprehenderit sint facilis excepturi modi architecto illum alias. ",
    published: "30th May 2020",
    coverImage: "https://images.ctfassets.net/9h27vede8di8/13Y82RTprSsYvUrCHem1GU/fe179cba963a0019a8b7cdb2a9cfc4d3/mercedes-benz-2498264_1920.jpg?w=2880&&q=80",
}

export default FeaturedPost
